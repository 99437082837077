.langSelector{
  .langSelectorMenu {
    background-color: #f3f4f7;
  }

  .iconFlag {
    border: 2px solid #374253;
    border-radius: 3px;
    width: 25px;
    height: auto;
  }

  .dropDownToggle,
  .dropdownItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}


.externalLangSelector {
  border: 1px solid #e6e6e6;
  border-radius: 0;
  background-color: #434343;
  margin: 5px;

  .iconFlag {
    width: 16px;
    height: 16px;
    font-size: 16px;
  }

  button,
  ul > li > a {
    background-color: #434343;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 0;

    &:hover,
    &:active,
    &:focus-visible {
      background-color: #8f8f8f;
    }
  }

  ul {
    border: 1px solid #e6e6e6;
    border-radius: 0;
    background-color: #434343;
    color: #fff;
  }
}
