.bodyContainer {
  padding: 0;
}

.accordionBodyContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  .listsBlockContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 190px;

    .listBlock{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 10px;

      ul {
        padding-left: 20px;
        list-style-type: square;
      }
    }
  }
}
