.title {
  text-align: center;
  padding-bottom: 20px;
  font-size: 30px;
}

.ucsLogo {
  width: 60%;
  margin-bottom: 10px;
}

.text {
  border-bottom: 1px;
  border-bottom-style: solid;
  padding-bottom: 10px;
  font-size: 18px;
}
