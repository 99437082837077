.container {
  text-align: center;
  height: 555px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.iconSize {
  width: 150px;
  height: 150px;
}
