.deleteIcon {
  cursor: pointer;
  //color: red;
}

.deleteIcon:hover {
  cursor: pointer;
  color: red;
}

.fileName{
  font-size: 0.8em;
  padding-right: 15px;
  display: inline-block;
}

.fileSize{
  font-size: 0.8em;
  color: grey;
  margin: 0 5px;
}

.displayBlock{
  display: block !important;
}

.displayNone{
  display: none;
}
