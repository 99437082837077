.commentColumn {
  max-width: 100vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.carLink {
  padding-left: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  .carName {
    color: #2c384af2;

    &:hover {
      color: #321fdb;
    }
  }
}

.idStyle {
  border: 2px solid;
  padding: 0 5px;
  font-weight: bold;
}
