.changePasswordForm {
  .titleField {
    font-size: 14px;
    padding: 0 0 2px;
    margin: 5px 0 0 0;
  }

  .separateLine {
    border-bottom: 1px solid #d8dbe0;
    width: 100%;
    height: 0;
    margin: 15px 0 10px 0;
  }

  .invalidFeedback {
    font-size: 14px;
    margin: 0;
    height: 20px;
  }
}
