.ucsLogo {
  width: 60%;
  margin-bottom: 10px;
}

.actionText {
  span, a {
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    color: white;

    &:hover {
      color: lightgray;
      cursor: pointer;
    }
  }
}
