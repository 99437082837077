.feedBackButton {
  cursor: pointer;
  border-radius: 25px;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #3c4b64;
  }
}

.itemMenu {
  height: 40px;
}

.avatarButton {
  background-color: #3c4b64;
  color: #fff;
}

.containerHeaderMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .containerRightBlockMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;

    .separateLine {
      width: 2px;
      height: 30px;
      background-color: #3c4b64;
      margin: 0 3px;
    }
  }

  .iconContainer {
    width: 40px;
    margin-right: 3px;
    height: 40px;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
      border: 2px solid #3c4b64;
    }
  }

}
