.editOrderButton {
  min-width: 125px;
  margin-left: 10px;
}

.viewOrderButton {
  min-width: 125px;
}

.carTitle {
  display: flex;
  align-items: baseline;
}

.comment {
  overflow: auto;
  border: 1px solid #b1b7c1;
  border-radius: 0.375rem;
  padding: 0 10px;
}
