.permissionToast {
  position: fixed;
  top: 50px;
  right: 50px;
  z-index: 10001;

  .permissionToastBody,
  .permissionToastHeader {
    background-color: #fff;
  }
}
