.formWrap {
  padding: 5px;
  border: solid 1px #b1b7c1;
  border-radius: 5px;
  height: 90px;
}

.formContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
}

.iconFormContainer {
  margin-top: 30px;
}

.cardTitle {
  margin-top: 15px;
  padding: 5px;
  font-weight: bold;
}

.selectedTableWrap {
  padding: 5px;
  border: solid 1px #b1b7c1;
  border-radius: 5px;
}
