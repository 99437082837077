.messageWrapper {
  border: 1px solid #105d89;
  border-radius: 0.375rem;
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #105d89;

  .messageTitle {
    font-size: 16px;
    font-weight: 700;
  }

  .messageText {
    font-size: 16px;
  }
}
