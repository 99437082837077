.navTab {
  position: relative;
  margin-top: 10px;

  .tab {
    padding-right: 25px;
    padding-left: 25px;
  }

  .tab:hover {
    border-color: transparent;
    cursor: pointer;
  }

  .activeTab {
    padding-right: 25px;
    padding-left: 25px;
  }

  .activeTab:hover {
    cursor: pointer;
  }

  .badge {
    margin-top: 10px;
    margin-left: -15px;
    font-size: 9px;
  }
}
