.appToast {
  position: absolute;
  top: 5px;
  right: 0;
  left: 0;
  z-index: 999;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  padding: 5px;
  font-size: medium;

}

.warn {
  background-color: rgba(255, 204, 0, 0.8);
  color: #503301;
  border: 1px solid #503301;
}

.info {
  background-color: rgba(50, 31, 219, 0.8);
  color: white;
  border: 1px solid white;
}
