.editCarButton {
  min-width: 125px;
  margin-left: 10px;
}

.viewCarButton {
  min-width: 125px;
}

.carInfo {
  overflow: auto;
  border: 1px solid #b1b7c1;
  border-radius: 0.375rem;
  padding: 0 10px;
}
