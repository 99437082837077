.favCarWorkTableWrap {
  height: 400px;
  padding: 5px;
  border: solid 1px #b1b7c1;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.cardTitle {
  margin-top: 15px;
  padding: 5px;
}

.selectedWorkTableWrap {
  padding: 5px;
  border: solid 1px #b1b7c1;
  border-radius: 5px;
}
