.cardContainer {
  border: solid 1px #DBDFE6;
  border-radius: 6px;
  margin-bottom: 20px;
  background: #F3F4F7;

  .cardTitle {
    border-bottom: solid 1px #DBDFE6;
    padding: 15px;
    margin: 0;
  }

  .photoContainer {
    width: 136px;
    height: 136px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #AFAFAF;
    border-radius: 5px;
  }

  .icon {
    width: 90%;
    height: 90%;
  }

}
