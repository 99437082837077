table {
  .allSalaryCell {
    background-color: #ebedef;
    font-weight: 700;
  }

  .finInfoCell {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}

.footerContainer {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 31%;
    padding: 8px;

    span:nth-child(2) {
      font-weight: 700;
    }
  }

  .cellValue {
    background-color: #ebedef;
    padding: 8px;
    font-weight: 700;

    &.rate {
      flex: 0 0 9%;
    }

    &.hourlyRate {
      flex: 0 0 12%;
    }

    &.percentWorks {
      flex: 0 0 10%;
    }

    &.percentSpares {
      flex: 0 0 12%;
    }

    &.bonus {
      flex: 0 0 9%;
    }

    &.penalty {
      flex: 0 0 9%;
    }

    &.salaryAll {
      flex: 0 0 8%;
    }
  }
}

.nameLink {
  text-decoration: none;
  color: #2c384af2;
  font-weight: bold;
  padding: 0;
  text-align: left;

  &:hover {
    text-decoration: underline;
    color: #321fdb;
  }
}
