td.infoCell {
  max-width: 0;

  .content {
    max-width: 100vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.tooltip {
  background-color: #2e3c53;
  opacity: 1;
  max-width: 330px;
  z-index: 1800;
  text-align: center;
}

.nameLink {
  text-decoration: none;
  color: #2c384af2;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: #321fdb;
  }
}
