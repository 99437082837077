.wrapperTable {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .title {
    font-size: 16px;
    padding-bottom: 5px;
  }

  .table {
    background-color: #f3f4f7;
    padding: 10px;
    border: 1px solid #dbdfe6;
    border-radius: 0.375rem;
    width: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    .tableBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 0 auto;
      gap: 5px;

      .tableField {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .tableFieldLabel {
          flex: 0 1 auto;
          border: 1px solid #dbdfe6;
          border-radius: 0.375rem 0 0 0.375rem;
          background-color: #f3f4f7;
          padding: 2px 5px;
        }

        .tableFieldValue {
          flex: 1 0 auto;
          border: 1px solid #dbdfe6;
          border-radius: 0 0.375rem 0.375rem 0;
          background-color: #fff;
          padding: 2px 5px;
          font-weight: bold;
        }
      }
    }
  }
}
