.fieldWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  .tableFooter {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 10px;

    &.alignRight {
      justify-content: flex-end;
    }
  }
}

.tableWrapper {
  max-height: 200px;
  overflow: auto;
  border: 1px solid #b1b7c1;
  border-radius: 0.375rem;
  padding: 0 10px;

  .removeButton {
    cursor: pointer;
    vertical-align: middle;
  }
}

.table {
  position: relative;

  tr {
    height: 42px;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #ffffff;
  }
}
