.infoBlockContainer {
  background-color: #f3f4f7;
  border: 1px solid #c5c5c5;
  border-radius: 0.375rem;
  padding: 5px 20px;

  .picture {
    float: right;
    padding: 0 0 5px 5px;
  }

  .textBlock {
    font-size: 16px;
    text-align: justify;

    .textBlockTitle {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}

.pictureModalBody {
  background-color: #f3f4f7;
}
