.infoColumn {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.carWorkName {
  color: #2c384af2;
  font-weight: bold;

   &:hover {
    color: #321fdb;
    text-decoration: underline;
    cursor: pointer;
  }
}

