.itemRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  .itemLabel {
    font-size: 14px;
    flex: 0 0 26%;
  }

  .itemValue {
    font-size: 18px;
    font-weight: 700;
  }

  .itemContactContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  .itemRegistrationDateContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;

    .workPeriodValue {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      border: 1px solid #d8dbe0;
      border-radius: 8px;
      background-color: #a8fea6;
      padding: 5px 10px;

      &.dismissal {
        background-color: #ffafb4;
      }

      span {
        font-weight: 700;
      }
    }
  }
}

.itemButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
