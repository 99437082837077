// Here you can add other styles
.react-datepicker__input-container {
  input {
    display: block;
    width: 100%;
    height: 38px;
    padding: 0.375rem 0.75rem;
    color: var(--cui-input-color, rgb(44 56 74 / 95%));
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    background-color: var(--cui-input-bg, #ffffff);
    background-clip: padding-box;
    border: 1px solid var(--cui-input-border-color, #b1b7c1);
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }

  input.react-datepicker-ignore-onclickoutside {
    color: var(--cui-input-focus-color, rgb(44 56 74 / 95%));
    background-color: var(--cui-input-focus-bg, #ffffff);
    border-color: var(--cui-input-focus-border-color, #998fed);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(50 31 219 / 25%);
  }
}

.react-datepicker-popper {
  .react-datepicker__day--outside-month {
    color: #9da5b1;
  }
}

.react-calendar-wrapper {
  .react-datepicker-popper {
    z-index: 2
  }
}

html:not([dir='rtl']) textarea.car-service-info.form-control.is-invalid {
  background-position: top 10px right 20px;
}

.nav-group-items {
  .nav-item {
    margin-left: 10px;
  }
}

.time-picker-roll {
  justify-content: space-between;

  .time-picker-roll-col {
    flex: 1 0 auto;
  }
}

%form-scroll {
  width: 100%;
  padding-bottom: var(--moad-footer-height);
  overflow-x: hidden;
  overflow-y: auto;
}

.form-scroll {
  @extend %form-scroll;

  &-no-padding {
    @extend %form-scroll;

    padding: 0;
  }
}

.sidebar-brand {
  background-color: #07080c;
}

