.carStateNumberWrap {
  display: flex;

  .carStateNum {
    display: flex;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;

    .carStateNumLeftSide {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      width: 20px;
      height: 100%;
      background-color: #02339c;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;

      .carStateNumLeftSideIcon {
        padding-top: 3px;
      }

      .carStateNumLeftSideText {
        padding-top: 3px;
        color: white;
        font-size: 10px;
      }
    }

    .carStateNumRightSide {
      margin-right: 3px;
      margin-left: 3px;
      text-align: center;
      width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .carStateNumRightSideWithActions {
      margin-right: 3px;
      margin-left: 3px;
      text-align: center;
      width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        color: #1061C3;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
