.dataPickerContainer {
  :global {
    .rs-picker {
      width: 100%;
    }

    .rs-picker-toggle {
      height: 38px;
      border: 1px solid #b1b7c1;
    }
  }

  .errorDateField div {
    border-color: #e55353;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.bonusContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;

  .itemInput {
    text-align: left;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  .unit {
    padding: 7px 15px;
    background-color: #ebedef;
    border: 1px solid #cbcbd0;
    border-radius: 4px;
  }
}
