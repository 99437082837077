.passwordHiddeIcon {
  background: white;
  border-left: none;
}

.passwordHiddeIcon:hover {
  cursor: pointer;
}

.invalidFeedback {
  width: 100%;
  margin-top: 5px;
  color: #e55353;
  font-size: 15px;
}

.invalidFeedbackHidden {
  display: none;
}
