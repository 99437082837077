.filterContainer {
  background-color: #ebedef;
  padding: 9px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataPickerContainer {
  :global {
    .rs-picker {
      width: 200px;
    }

    .rs-picker-toggle {
      height: 38px;
      border: 1px solid #b1b7c1;
    }

    .rs-picker-toggle-value {
      text-transform: capitalize;
    }
  }
}
