.gdprLink {
  margin-top: -5px;
  color: #374253;

  &:hover {
    color: #6d7582;
  }
}

.ucsLogo {
  width: 60%;
  margin-bottom: 10px;
}
