.info {
  overflow: auto;
  border: 1px solid #b1b7c1;
  border-radius: 0.375rem;
  padding: 0 10px;
}

.suppliersName {
  color: #2c384af2;
  font-weight: bold;

  &:hover {
    color: #321fdb;
    cursor: pointer;
    text-decoration: underline;
  }
}
