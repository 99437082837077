.certificateButton {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    margin-left: 10px;
  }
}

.dropDownItem{

  &:hover {
    cursor: pointer;
    background: #D49612;
  }
}
