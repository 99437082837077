body {
  :global {
    .ucs-select__menu-portal {
      z-index: 1100;
      font-size: 1rem;
      color: #2c384a;

      .ucs-select__footer-option {
        font-size: inherit;
        width: 100%;
        background-color: transparent;
        color: inherit;
        padding: 8px 12px;
        box-sizing: border-box;
      }
    }
  }
}

.uscSelect {
  font-size: 1rem;
  color: #2c384a;

  :global {
    .ucs-select__control {
      border: 1px solid #b1b7c1;
      border-radius: 0.375rem;

      &:hover {
        border-color: #b1b7c1;
      }

      &--is-focused {
        border-color: #998fed;
        box-shadow: 0 0 0 0.25rem rgba(50, 31, 219, 0.25);
      }

      &--is-disabled {
        background-color: #d8dbe0;
      }
    }

    .ucs-select__input-container {
      font-size: 1rem;
      color: #2c384a;
    }

    .ucs-select__single-value--is-disabled {
      color: #2c384a;
    }
  }

  &.isError {
    :global {
      .ucs-select__control {
        border: 1px solid #e55353;

        &:hover {
          border-color: #e55353;
        }

        &--is-focused {
          box-shadow: 0 0 0 0.25rem rgba(229, 83, 83, 0.25);
        }
      }
    }
  }
}
