.filterWrapper {
  font-size: 14px;

  .fieldStatus {
    width: 150px;

    input::placeholder {
      font-size: 14px;
    }

    div[role='menu'] {
      min-width: 300px;
    }
  }

  .dateRangeField {
    width: 320px;

    input {
      &::placeholder {
        font-size: 14px;
      }

      padding: 6px;
      font-size: 14px;
    }
  }
}
