.cardContainer {
  border: solid 1px #DBDFE6;
  border-radius: 6px;
  background: #F3F4F7;
  margin-bottom: 20px;

  .cardTitle {
    border-bottom: solid 1px #DBDFE6;
    padding: 15px;
    margin: 0;
  }
}

.formControl {
  width: 800px !important;
}
