.sparePartLink {
  padding-left: 0;
  margin-left: 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.sparePartName {
  color: #2c384af2;
  font-weight: bold;

   &:hover {
    color: #321fdb;
    cursor: pointer;
    text-decoration: underline;
  }
}

