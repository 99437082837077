.tariffsContainer {
  display: flex;
  justify-content: space-evenly;

  .tariffPanelWrapper {

    .tariffTooltip {
      background-color: #2e3c53;
      opacity: 1;
      max-width: 330px;
      z-index: 1800;
      text-align: center;
    }

    .currentPanel,
    .nextPanel,
    .finishedPanel,
    .emptyPanel {
      color: #fff;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .currentPanel,
    .nextPanel,
    .finishedPanel {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      &+.tariffPanelContainer {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .emptyPanel {
      visibility: hidden;
    }

    .currentPanel {
      background-color: #2eb85c;
    }

    .nextPanel {
      background-color: #3399ff;
    }

    .finishedPanel {
      background-color: #e55353;
    }
  }

  .tariffPanelContainer {
    border-radius: 3px;
    border: 1px solid #000;
    background: #3c4b64;
    width: 200px;

    .tariffPanelTitle {
      color: #FFF;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .tariffPanelCondition,
    .tariffPanelInformation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fffdfd;
      font-size: 12px;
      font-weight: 400;
      padding: 0 5px;
    }

    .tariffPanelInformation {
      text-align: center;
      justify-content: center;
    }

    .tariffPanelRow {
      height: 41px;
      background: #2e3c53;

      &:nth-child(2n) {
        background: #3c4b64;
      }

      &:nth-child(1),
      &:nth-child(6) {
        border-bottom: 1px solid #000;
      }
    }

    .tariffPanelFooter {
      padding: 2px 5px;
      background: #3c4b64;
      color: #fffdfd;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;

      input[type='range'] {
        height: 16px;

        &::-webkit-slider-runnable-track,
        &::-moz-range-track {
          height: 5px;
        }

        &::-webkit-slider-thumb {
          height: 12px;
          width: 12px;
          transform: translateY(2px);
        }
      }

      .footerTitle {
        font-size: 13px;
        line-height: 13px;
        padding-bottom: 2px;
      }

      .priceValue {
        font-size: 16px;
      }

      .disableButton {
        background: #3c4b64;
        color: #fffdfd;
      }
    }
  }
}
