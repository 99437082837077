.totalField {
  display: flex;
  justify-content: flex-start;
  align-content: baseline;
  font-size: 28px;

  .totalField_label {
    color: #575757;
  }

  .totalField_value {
    color: #8b0000;
  }

  &.subField {
    font-size: 14px;
    font-weight: 700;

    .totalField_value,
    .totalField_label {
      border: 1px solid #b1b7c1;
      padding: 2px 10px;
    }

    .totalField_label {
      color: #212631;
      background-color: #f3f4f7;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    .totalField_value {
      background-color: #ffffff;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
