.imagePreview {
  cursor: zoom-in;
}

.viewModal {
  .viewModalBody {
    padding: 10px;
    border-radius: 0.5rem;
    text-align: center;
    cursor: zoom-out;
  }
}
