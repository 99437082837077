.cardContainer {
  border: solid 1px #DBDFE6;
  border-radius: 6px;
  margin-bottom: 20px;
  background: #F3F4F7;

  .cardTitle {
    border-bottom: solid 1px #DBDFE6;
    padding: 15px;
    margin: 0;
  }

  .icon {
    width: 20px;
    height: 20px;
    padding-left: 3px;
  }
}
