.actionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  .tip {
    padding: 3px;
    background: #383838;
  }

  .iconContainer {
    width: 35px;
    margin-right: 15px;
    height: 35px;
    border: 1px solid #d5d8d9;
    border-radius: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}
