.contact {
  a {
    color: white;
  }
}

@media (max-width: 1400px) {
  .contact {
    flex-direction: column;
  }
}

