.profileContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 15px;
  font-weight: 400;
  gap: 30px;

  .profileDataBlock {
    flex: 0 0 calc(50% - 15px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .profileDataRow {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 5px 10px;
      width: 100%;

      & + .profileDataRow {
        border-top: 1px solid var(--cui-card-border-color);
      }

      .profileDataLabel {
        flex: 0 0 30%;
      }

      .profileDataValue {
        flex: 0 0 70%;

        .activeValue {
          color: var(--cui-green);
        }

        .noActiveValue {
          color: var(--cui-red);
        }
      }

      .profileDataValueEdit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .profileDataValueContent {
          flex: 1 0 auto;
          position: relative;

          .profileDataValueContentInput {
            padding: 0;
            height: 20px;
            border: none;
            border-radius: 0;
            border-bottom: 1px solid var(--cui-input-border-color, #b1b7c1);
          }

          .phoneInput {
            padding-top: 0;
            padding-bottom: 0;
            border: 0;
            height: 20px;
          }

          .phoneDropdown {
            border: 0;
            background: #fff;
          }
        }

        .profileDataValueActions {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;

          .profileDataValueButton {
            padding: 0;
            height: 20px;
            z-index: 3;
          }
        }
      }

      .profileDataValueMulti {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;

        & > span {
          flex: 0 0 50%
        }
      }

      .linkButton {
        font-size: 15px;
        color: #1675e0;
        padding: 0;
        text-decoration: none;

        &:hover {
          color: #0a5dc2;
          text-decoration: underline;
        }
      }
    }
  }
}
