.widgetsContainer {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 2fr 1fr 1fr 2fr;
  grid-template-rows: 150px 150px;
  grid-template-areas:
    "commonInfo workHour ordersDone salary"
    "commonInfo worksDone normHour salary";
  margin: 9px 0;
  color: #364152;

  .widgetItem {
    border: 2px solid #706994;
    border-radius: 4px;
    padding: 12px;

    &.commonIfo {
      grid-area: commonInfo;
    }

    &.workHour {
      grid-area: workHour;
    }

    &.ordersDone {
      grid-area: ordersDone;
    }

    &.worksDone {
      grid-area: worksDone;
    }

    &.normHour {
      grid-area: normHour;
    }

    &.salary {
      grid-area: salary;
    }
  }
}

.commonInfoWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  .fullName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .icon {
      width: 45px;
      height: 45px;
      color: #706994;
    }

    .value {
      border: 1px solid #cbcbd0;
      border-radius: 8px;
      background-color: #ebedef;
      padding: 7px 12px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: #cbcbd0;
      }
    }
  }

  .position {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  .methodsSalary {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;

    .methodSalaryItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #cbcbd0;
      border-radius: 4px;
      width: 100%;

      .methodSalaryLabel {
        padding: 7px;
        border-right: 1px solid #cbcbd0;
        flex: 0 0 70%;
      }

      .methodSalaryValue {
        padding: 7px;
        background-color: #ebedef;
        font-weight: 700;
        height: auto;
        flex: 0 0 30%;
      }

      &.isPercent {
        .methodSalaryLabel {
          flex: 0 0 85%;
        }

        .methodSalaryValue {
          flex: 0 0 15%;
        }
      }
    }
  }
}

.salaryWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  .title {
    align-self: center;
    text-decoration: underline;
    text-align: center;
  }

  .infoSalary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    .icon {
      width: 50px;
      height: 50px;
      color: #2eb85c;
    }

    .infoSalaryValue {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;

      span:nth-child(2) {
        color: #f0ae4e;
      }
    }
  }

  .bonusesBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    .bonusItem {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;

      .bonusLabel,
      .fineTitle {
        text-decoration: underline;
      }

      .bonusValue {
        color: #2eb85c;
      }

      .fineValue {
        color: #e55353;
      }

      .totalValue {
        color: #f0ae4e;
      }
    }
  }

  .paymentBLock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    .paymentItem {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 5px;

      .paidValue {
        color: #418cff;
      }

      .payValue {
        color: #f0ae4e;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}

.smallWidget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;

  .title {
    align-self: center;
    text-decoration: underline;
    text-align: center;
  }

  .description {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;

    .icon {
      width: 35px;
      height: 35px;
      color: #706994;
    }

    .value {

    }
  }
}
