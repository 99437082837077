.salaryFieldContainer {
  &:not(:first-child) {
    margin-top: 15px;
  }
}

.salaryFieldItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .itemDescription {
    padding: 6px 12px;
    background-color: #e7eaee;
    border: 1px solid #dbdfe6;
    border-radius: 3px;
    width: 395px;
  }

  .itemInput {
    width: 120px;
    text-align: right;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  .unit {
    width: 25px;
  }
}

.dataPickerContainer {
  :global {
    .rs-picker {
      width: 100%;
    }

    .rs-picker-toggle {
      height: 38px;
      border: 1px solid #b1b7c1;
    }
  }

  .errorDateField div {
    border-color: #e55353;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.containerPhoneField {
  input.phoneFieldInput {
    width: 100%;
  }
}

body {
  :global {
    .rs-picker-menu {
      z-index: 1100;
    }
  }
}

.modalFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--top-layer);
  height: var(--moad-footer-height);
  background-color: white;
}
