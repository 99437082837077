.searchBtn {
  display: flex;
  align-items: center;
  border: 1px solid #f9b115 ;
  color: #f9b115 ;
  border-radius: 5px;
  height: 38px;
}

.searchBtnDisabled {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  color: lightgray;
  border-radius: 5px;
  height: 38px;
}

.searchBtn:hover {
  cursor: pointer;
  color: black;
  background: #f9b115;
}

.carStateNumLeftSide {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 25px;
  height: 100%;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.bColor{
  background-color: #02339c;
}

.bColorDisabled {
  background-color: lightgray;
}

.carStateNumLeftSideIcon {
    padding-top: 3px;
  }

.carStateNumLeftSideText {
    padding-top: 3px;
    color: white;
    font-size: 10px;
  }

.modalBody {
  border: 1px solid lightgray;
  margin: 5px;
  padding: 5px;
  border-radius: 3px;
  background: #f3f4f7;
}

.row {
  flex-grow: 1;
  display: flex;
  border: 1px solid #d8dbe0;
  border-radius: 5px;

  .labelRow {
    border-right: 1px solid #d8dbe0;
    padding-left: 5px;
    color: #404652;
  }

  .valueRow {
    background: white;
    padding-left: 5px;
    font-weight: bold;
    color: #374253;
  }
}

.descRow {
  border-top: 1px solid #d8dbe0;
}

.confirmFillFormModal {
  position: fixed;
  z-index: 99999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.confirmFillFormModalContent {
  background-color: #ffffff;
  margin: auto;
  z-index: 99999;
  border: 1px solid #888;
  width: 30%;
  border-radius: 10px;
}

.modalHeader{
  cursor: move;
  padding: 15px;
  border-bottom: 1px solid #d8dbe0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@media (max-width: 1000px) {
  .confirmFillFormModalContent {
    width: 100%;
  }
}
