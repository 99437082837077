.container {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  --cui-spinner-width: 35px;
  --cui-spinner-height: 35px;
  --cui-spinner-border-width: 7px;
}
