.message {
  margin: 20px 0;
  padding: 20px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
}

.error {
  color: var(--msg-error);
}

.success {
  color: var(--msg-success);
}

.default {
  color: var(--msg-default);
}
