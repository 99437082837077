.infoSectorContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 25px;

  .infoSectorBlocks {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 35px;
  }

  @media (max-width: 1000px) {
    .infoSectorBlocks {
      flex-direction: column;
    }
  }

}
