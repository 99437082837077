$phone-color: #ffffff;
$viber-color: #ad3ee5;
$telegram-color: #3eafe5;
$email-color: #ffffff;

.contactFieldContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  border-bottom: 1px solid #d8dbe0;
  border-bottom-left-radius: 10px;

  &.phone {
    .iconContainer {
      background-color: $phone-color;
      color: #000000;
    }
  }

  &.viber {
    .iconContainer {
      background-color: $viber-color;
    }
  }

  &.telegram {
    .iconContainer {
      background-color: $telegram-color;
    }
  }

  &.email {
    .iconContainer {
      background-color: $email-color;
      color: #000000;
    }
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #d8dbe0;
    border-radius: 10px;
    color: #ffffff;

    .icon {
      width: 14px;
      height: 14px;
      font-size: 14px;
      fill: #ffffff;
    }
  }

  .value {
    font-size: 14px;
    font-weight: 700;
    margin: 0 3px 0 22px;

    &.isEmpty {
      width: 120px;
    }
  }
}
