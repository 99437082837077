.inputTitle {
  font-size: 16px;
  padding-bottom: 5px;
}

.groupInput {
  .inputVin {
    flex: 0 0 300px;
  }

  .inputAutoGosNumber {
    flex: 0 0 210px;
  }
}

.gorupFeedback {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .feedbackVin {
    display: block;
    flex: 0 0 300px;
  }

  .feedbackGos {
    display: block;
    flex: 0 0 210px;
  }
}
