.commentColumn {
  max-width: 100vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.idStyle {
  border: 2px solid;
  padding: 0 5px;
  font-weight: bold;
}
